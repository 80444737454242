import React, { useEffect, useState } from "react";
import { IoSettings } from "react-icons/io5";
import Select from "react-select";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../../constants/user";
import { toast } from "react-toastify";
function TimelogRestriction() {
  const [project, setProject] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState();
  const [loginData, setLoginData] = useState(null);
  const [adUsers, setAdusers] = useState([]);
  const refresh_token = getLocalStorageData("refresh_token");

  const currentUserId = getLocalStorageData("access_token");

  useEffect(() => {
    const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
    setLoginData(token);
    fetchADUsers();

  }, [currentUserId]);

  const fetchADUsers = async () => {
    try {
      const exptime = getLocalStorageData("expiredTime");

      if (exptime < new Date().toISOString()) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}Login/GetRefreshToken`,
            { refreshToken: refresh_token }
          );
          const { access_token, expires_in } = response?.data?.result;
          setLocalStorageData(
            new Date(new Date().getTime() + expires_in * 1000),
            "expiredTime"
          );
          setLocalStorageData(access_token, "access_token");
          setUserId(getLocalStorageData("access_token"));
          const adresponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`,
            { token: access_token }
          );
          setAdusers(adresponse.data.value);
        } catch (error) {
          console.error("Error fetching refresh token:", error);
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`,
          { token: userId }
        );
        setAdusers(response.data.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`
      );
      setProject(response.data.value);
    } catch (error) {
      console.log(error);
    }
  };

  const saveRestriction = async () => {
    const body = {
      CurrentUserId: loginData?.oid,
      SelectedProjectIds: selectedIds?.map((item) => ({
        id:item.value,
        name:item.label
      }) ),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Setting/SaveRestrictionOnProject`,
        body
       
      );
      toast.success("Successfully added");
      GetExistingRestrictedProjects()
  
    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }
  
  };

  const saveTimeRestriction = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Setting/SaveDaysRestrictionOnTimeLog?CurrentUserId=${loginData?.oid}&Days=${inputValue}`,
      );
      
      setInputValue('');
      toast.success("Successfully added");
      GetDaysRestrictionOnTimeLog()
  
    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }
  
  };


  const GetExistingDevOpsUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Setting/GetExistingDevOpsUser?CurrentUserId=${loginData?.oid}`,
      );
      
       setSelectedProject(response.data);
  
    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }
  
  };

  
  const GetExistingRestrictedProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Setting/GetExistingRestrictedProjects?CurrentUserId=${loginData?.oid}`,
      );
      
       setSelectedIds(response.data);
  
    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }
  
  };
  const GetDaysRestrictionOnTimeLog = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Setting/GetDaysRestrictionOnTimeLog?CurrentUserId=${loginData?.oid}`,
      );
      
      setInputValue(response.data);
  
    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }
  
  };

  const DevOpsUser = async () => {
    const body = {
      CurrentUserId: loginData?.oid,
      userIds: selectedProject?.map((item) => ({
        id:item.value,
        name: item.label
      })),
      
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}Setting/SaveDevOpsUsers`,
        body
      );
      setSelectedProject([]);
      toast.success("Successfully added");
      GetExistingDevOpsUser()
    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }
  };

  useEffect(() => {
    setUserId(getLocalStorageData("access_token"));
    fetchProjectData();
  
  }, []);

  useEffect(() => {

    if (userId) {

      const token = userId !== null ? jwtDecode(userId) : "";

      setLoginData(token);

      fetchADUsers();

    }

  }, [userId]);

  useEffect(()=>{
    
    if(adUsers.length>0){
      GetExistingRestrictedProjects();
      GetDaysRestrictionOnTimeLog();
      GetExistingDevOpsUser();
    }
   
  },[adUsers])


  const projectOptions = project.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const allUSer = adUsers.map((item) => ({
    ...item,
    value: item.id,
    label: item.displayName,
  }));

  const handleAccessChange = (selectedOptions) => {
    setSelectedIds(selectedOptions);
  };

  const handleAccessDevChange = (selectedProject) => {
    setSelectedProject(selectedProject);
  };
  const handleInput = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) <= 31 && Number(value) >= 0)) {
      setInputValue(value);
    }
  };


  return (
    <div className="px-8 py-4">
      <div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center justify-between gap-2">
            <IoSettings className="text-yellow text-lg" />
            <strong className="text-lg">Setting</strong>
          </div>
        </div>
        <div className="border border-gray mb-10">
          <div>
            <h2 className="text-lg font-bold  p-4 bg-secbg">
              Project TimeLog Restriction
            </h2>
          </div>
          <div className="p-6 flex items-center gap-2">
            <label className="text-sm w-[100px] text-center">
              Select Project
            </label>
            <Select
              isMulti
              options={projectOptions}
              placeholder="Select options"
              className="basic-multi-select w-full text-[12px]"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              onChange={handleAccessChange}
              value={selectedIds}
            />
          </div>
          <div className="p-6 text-right pt-0">
            {" "}
            <button
              onClick={() => saveRestriction()}
              className="bg-yellow hover:bg-blue-700 px-6 text-white text-sm h-[38px]font-medium py-1  rounded  pt-1"
            >
              Save
            </button>
          </div>
        </div>

        <div className="border border-gray mb-10">
          <div>
            <h2 className="text-lg p-4 bg-secbg   font-bold">
              Days TimeLog Restriction
            </h2>
          </div>
          <div className="p-6 flex items-center gap-2">
            <label className="text-sm  w-[100px] text-center">
              Select Days
            </label>
            <input
              type="Number"
              value={inputValue}
              onChange={handleInput}
              name="value"
              className="timeTextBox flex-grow pl-1 cursor-pointer max-w-[200px]  border  text-[12px] h-[35px]  rounded-md border-gray text-black"
            ></input>
          </div>
          <div className="p-6 text-right pt-0">
            <button  onClick={() => saveTimeRestriction()} className="bg-yellow hover:bg-blue-700 px-6 text-white text-sm h-[38px]font-medium py-1  rounded  pt-1">
              Save
            </button>
          </div>
        </div>
        <div className="border border-gray mb-10">
          <div>
            <h2 className="text-lg font-bold  p-4 bg-secbg">DevOps Users</h2>
          </div>
          <div className="p-6 flex items-center gap-2">
            <label className="text-sm w-[100px] text-center">Select User</label>
            <Select
              isMulti
              options={allUSer}
              placeholder="Select options"
              className="basic-multi-select w-full text-[12px]"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              onChange={handleAccessDevChange}
              value={selectedProject}
            />
          </div>
          <div className="p-6 pt-0 text-right">
            <button onClick={()=>DevOpsUser()} className="bg-yellow hover:bg-blue-700 px-6 text-white text-sm h-[38px]font-medium py-1  rounded  pt-1">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelogRestriction;
